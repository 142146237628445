<template>
  <div>
    <div class="row">
      <div class="col-md-3 mb-3">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-column align-items-center text-center">
              <img
                src="https://p.kindpng.com/picc/s/78-785827_user-profile-avatar-login-account-male-user-icon.png"
                alt="Foto profil anggota"
                class="rounded-circle"
                width="150"
              />
              <div class="mt-3">
                <h4>{{ anggota.nama_lengkap }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-9">
        <ul class="nav nav-tabs" id="anggotaFormTab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="infoumum-tab"
              data-bs-toggle="tab"
              data-bs-target="#infoumum"
              type="button"
              role="tab"
              aria-controls="infoumum"
              aria-selected="true"
            >
              Informasi Umum
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="alamat-tab"
              data-bs-toggle="tab"
              data-bs-target="#alamat"
              type="button"
              role="tab"
              aria-controls="alamat"
              aria-selected="false"
            >
              Alamat
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="hobi-tab"
              data-bs-toggle="tab"
              data-bs-target="#hobi"
              type="button"
              role="tab"
              aria-controls="hobi"
              aria-selected="false"
            >
              Hobi & Pekerjaan
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="infokta-tab"
              data-bs-toggle="tab"
              data-bs-target="#infokta"
              type="button"
              role="tab"
              aria-controls="infokta"
              aria-selected="false"
            >
              KTA
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="ahliwaris-tab"
              data-bs-toggle="tab"
              data-bs-target="#ahliwaris"
              type="button"
              role="tab"
              aria-controls="ahliwaris"
              aria-selected="false"
            >
              Ahli Waris
            </button>
          </li>
        </ul>
        <div class="tab-content" id="anggotaFormContent">
          <div
            class="tab-pane fade show active"
            id="infoumum"
            role="tabpanel"
            aria-labelledby="infoumum-tab"
          >
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Informasi Umum</h5>
                <h6 class="card-subtitle mb-2 text-muted">
                  Informasi umum tentang anggota seperti nama, no hp, status,
                  email, dll.
                </h6>
                <hr />
                <div class="row mt-3 px-2">
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Nama Lengkap</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="anggota.nama_lengkap"
                      :class="{ 'is-invalid': $v.anggota.nama_lengkap.$error }"
                    />
                    <div
                      v-if="$v.anggota.nama_lengkap.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.anggota.nama_lengkap.required"
                        >Nama lengkap harus di isi.</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Nama Panggilan</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="anggota.nama_panggilan"
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Jenis Kelamin</label>
                    <select class="form-select" v-model="anggota.jenis_kelamin">
                      <option value="">Pilih...</option>
                      <option
                        v-for="option in jenis_kelamin"
                        :value="option"
                        :key="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Tanggal Lahir</label>
                    <v-date-picker
                      v-model="anggota.tanggal_lahir"
                      :locale="locale"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          class="form-control"
                          :value="inputValue"
                          v-on="inputEvents"
                        />
                      </template>
                    </v-date-picker>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Email</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="anggota.email"
                      :class="{ 'is-invalid': $v.anggota.email.$error }"
                    />
                    <div
                      v-if="$v.anggota.email.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.anggota.email.required"
                        >Email harus di isi.</span
                      >
                      <span v-if="!$v.anggota.email.email"
                        >Format email harus benar.</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">No. HP</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="anggota.no_hp"
                      :class="{ 'is-invalid': $v.anggota.no_hp.$error }"
                    />
                    <div
                      v-if="$v.anggota.no_hp.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.anggota.no_hp.required"
                        >Nomor HP harus di isi.</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Agama</label>
                    <select class="form-select" v-model="anggota.agama">
                      <option value="">Pilih...</option>
                      <option
                        v-for="option in agama"
                        :value="option"
                        :key="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Status</label>
                    <select class="form-select" v-model="anggota.status">
                      <option value="">Pilih...</option>
                      <option
                        v-for="option in status"
                        :value="option"
                        :key="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Jenis Identitas</label>
                    <select
                      class="form-select"
                      v-model="anggota.jenis_identitas"
                      :class="{
                        'is-invalid': $v.anggota.jenis_identitas.$error,
                      }"
                    >
                      <option value="">Pilih...</option>
                      <option
                        v-for="option in jenis_identitas"
                        :value="option"
                        :key="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                    <div
                      v-if="$v.anggota.jenis_identitas.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.anggota.jenis_identitas.required"
                        >Jenis Identitas harus di isi.</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">No. Identitas</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="anggota.no_identitas"
                      :class="{ 'is-invalid': $v.anggota.no_identitas.$error }"
                    />
                    <div
                      v-if="$v.anggota.no_identitas.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.anggota.no_identitas.required"
                        >No. Identitas harus di isi.</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="alamat"
            role="tabpanel"
            aria-labelledby="alamat-tab"
          >
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Domisili KTP</h5>
                <h6 class="card-subtitle mb-2 text-muted">
                  Informasi Alamat sesuai KTP
                </h6>
                <hr />
                <div class="row mt-3 px-2">
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Provinsi</label>
                    <v-select
                      v-model="anggota.propinsi_id"
                      :options="provinsi"
                      :reduce="(provinsi) => provinsi.id"
                      label="nama"
                      @input="onProvinceChange"
                      :class="{ 'is-invalid': $v.anggota.propinsi_id.$error }"
                    >
                      <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :class="{
                            'is-invalid': $v.anggota.propinsi_id.$error,
                          }"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                    </v-select>
                    <div
                      v-if="$v.anggota.propinsi_id.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.anggota.propinsi_id.required"
                        >Provinsi harus di isi.</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Kota/Kab.</label>
                    <v-select
                      v-model="anggota.kota_id"
                      :options="kota"
                      :reduce="(kota) => kota.id"
                      label="nama"
                      @input="onKotaChange"
                      :class="{ 'is-invalid': $v.anggota.kota_id.$error }"
                    >
                      <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :class="{
                            'is-invalid': $v.anggota.kota_id.$error,
                          }"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                    </v-select>
                    <div
                      v-if="$v.anggota.kota_id.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.anggota.kota_id.required"
                        >Kota harus di isi.</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Kecamatan</label>
                    <v-select
                      v-model="anggota.kecamatan_id"
                      :options="kecamatan"
                      :reduce="(kecamatan) => kecamatan.id"
                      label="nama"
                      @input="onKecamatanChange"
                      :class="{ 'is-invalid': $v.anggota.kecamatan_id.$error }"
                    >
                      <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :class="{
                            'is-invalid': $v.anggota.kecamatan_id.$error,
                          }"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                    </v-select>
                    <div
                      v-if="$v.anggota.kecamatan_id.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.anggota.kecamatan_id.required"
                        >Kecamatan harus di isi.</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Kelurahan</label>
                    <v-select
                      v-model="anggota.kelurahan_id"
                      :options="kelurahan"
                      :reduce="(kelurahan) => kelurahan.id"
                      label="nama"
                    >
                    </v-select>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label class="form-label">Alamat</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="anggota.alamat"
                      :class="{ 'is-invalid': $v.anggota.alamat.$error }"
                    />
                    <div
                      v-if="$v.anggota.alamat.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.anggota.alamat.required"
                        >Alamat harus di isi.</span
                      >
                    </div>
                  </div>
                </div>
                <hr />
                <h5 class="card-title">Domisili saat ini</h5>
                <h6 class="card-subtitle mb-2 text-muted">
                  Informasi Alamat saat ini
                </h6>
                <hr />
                <div class="row mt-3 px-2">
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Provinsi</label>
                    <v-select
                      v-model="anggota.current_domisili_propinsi_id"
                      :options="provinsiDomisili"
                      :reduce="(provinsi) => provinsi.id"
                      label="nama"
                      @input="onDomisiliProvinceChange"
                    >
                    </v-select>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Kota/Kab.</label>
                    <v-select
                      v-model="anggota.current_domisili_kota_id"
                      :options="kotaDomisili"
                      :reduce="(kota) => kota.id"
                      label="nama"
                      @input="onDomisiliKotaChange"
                    >
                    </v-select>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Kecamatan</label>
                    <v-select
                      v-model="anggota.current_domisili_kecamatan_id"
                      :options="kecamatanDomisili"
                      :reduce="(kecamatan) => kecamatan.id"
                      label="nama"
                      @input="onDomisiliKecamatanChange"
                    >
                    </v-select>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Kelurahan</label>
                    <v-select
                      v-model="anggota.current_domisili_kelurahan_id"
                      :options="kelurahanDomisili"
                      :reduce="(kelurahan) => kelurahan.id"
                      label="nama"
                    >
                    </v-select>
                  </div>
                  <div class="col-md-12 mb-3">
                    <label class="form-label">Alamat</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="anggota.current_domisili_alamat"
                    />
                  </div>
                  <div class="col-md-12 mb-3">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="anggota.is_abroad"
                      />
                      <label class="form-check-label"
                        >Domisili luar negeri</label
                      >
                    </div>
                  </div>
                  <div class="col-md-6 mb-3" v-if="anggota.is_abroad">
                    <label class="form-label">Negara</label>
                    <v-select
                      v-model="anggota.country_id"
                      :options="countries"
                      :reduce="(country) => country.id"
                      label="name"
                    >
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="hobi"
            role="tabpanel"
            aria-labelledby="hobi-tab"
          >
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Hobi & Pekerjaan</h5>
                <h6 class="card-subtitle mb-2 text-muted">
                  Informasi tentang Hobi & Pekerjaan
                </h6>
                <hr />
                <div class="row mt-3 px-2">
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Kategori Hobi</label>
                    <v-select
                      v-model="anggota.kategori_hobi_id"
                      :options="kategori_hobi"
                      :reduce="(kategori_hobi) => kategori_hobi.id"
                      label="nama"
                      @input="onKategoriHobiChange"
                    >
                    </v-select>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Hobi</label>
                    <v-select
                      v-model="anggota.hobi_id"
                      :options="hobi"
                      :reduce="(hobi) => hobi.id"
                      label="nama"
                    >
                    </v-select>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Kategori Pekerjaan</label>
                    <v-select
                      v-model="anggota.kategori_pekerjaan_id"
                      :options="kategori_pekerjaan"
                      :reduce="(kategori_pekerjaan) => kategori_pekerjaan.id"
                      label="nama"
                      @input="onKategoriPekerjaanChange"
                    >
                    </v-select>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Pekerjaan</label>
                    <v-select
                      v-model="anggota.pekerjaan_id"
                      :options="pekerjaan"
                      :reduce="(pekerjaan) => pekerjaan.id"
                      label="nama"
                    >
                    </v-select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="ahliwaris"
            role="tabpanel"
            aria-labelledby="ahliwaris-tab"
          >
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Ahli Waris</h5>
                <h6 class="card-subtitle mb-2 text-muted">
                  Informasi tentang Ahli Waris
                </h6>
                <hr />
                <h5>Ahli Waris 1</h5>
                <div class="row mt-3 px-2">
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Nama</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="anggota.ahli_waris_1_nama"
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">NIK</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="anggota.ahli_waris_1_nik"
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">No. HP</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="anggota.ahli_waris_1_no_hp"
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Hubungan Keluarga</label>
                    <select
                      class="form-select"
                      v-model="anggota.ahli_waris_1_hubungan"
                    >
                      <option value="">Pilih...</option>
                      <option
                        v-for="option in hubungan_keluarga"
                        :value="option"
                        :key="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                  </div>
                </div>
                <hr />
                <h5>Ahli Waris 2</h5>
                <div class="row mt-3 px-2">
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Nama</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="anggota.ahli_waris_2_nama"
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">NIK</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="anggota.ahli_waris_2_nik"
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">No. HP</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="anggota.ahli_waris_2_no_hp"
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Hubungan Keluarga</label>
                    <select
                      class="form-select"
                      v-model="anggota.ahli_waris_2_hubungan"
                    >
                      <option value="">Pilih...</option>
                      <option
                        v-for="option in hubungan_keluarga"
                        :value="option"
                        :key="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="infokta"
            role="tabpanel"
            aria-labelledby="infokta-tab"
          >
            <div class="card">
              <div class="card-body">
                <h5 class="card-title">Informasi KTA</h5>
                <h6 class="card-subtitle mb-2 text-muted">
                  Informasi tentang KTA seperti status KTA, Foto, Jenis KTA,
                  dll.
                </h6>
                <hr />
                <div class="row mt-3 px-2">
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Jenis KTA</label>
                    <select
                      class="form-select"
                      v-model="anggota.jenis_atribut"
                      :class="{ 'is-invalid': $v.anggota.jenis_atribut.$error }"
                      @change="onJenisAtributChange"
                    >
                      <option value="">Pilih...</option>
                      <option
                        v-for="option in jenis_atribut"
                        :value="option"
                        :key="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                    <div
                      v-if="$v.anggota.jenis_atribut.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.anggota.jenis_atribut.required"
                        >Jenis Atribut harus di isi.</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Ukuran Atribut</label>
                    <select
                      class="form-select"
                      v-model="anggota.ukuran_atribut"
                      :class="{
                        'is-invalid': $v.anggota.ukuran_atribut.$error,
                      }"
                      :disabled="hanyaKta"
                    >
                      <option value="">Pilih...</option>
                      <option
                        v-for="option in ukuran_atribut"
                        :value="option"
                        :key="option"
                      >
                        {{ option }}
                      </option>
                    </select>
                    <div
                      v-if="$v.anggota.ukuran_atribut.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.anggota.ukuran_atribut.required"
                        >Ukuran Atribut harus di isi.</span
                      >
                    </div>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Status KTA</label>
                    <select
                      class="form-select"
                      v-model="anggota.status_anggota"
                    >
                      <option value="">Pilih...</option>
                      <option
                        v-for="option in status_anggota"
                        :value="option.value"
                        :key="option.value"
                      >
                        {{ option.label }}
                      </option>
                    </select>
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">Nomor KTA</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="anggota.nomor_kta"
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label class="form-label">QR Code</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="anggota.qr_code"
                    />
                  </div>
                </div>
                <div class="row" v-if="isEdit">
                  <div class="mt-3">
                    <button
                      class="btn btn-secondary mx-1"
                      @click="showFotoIdentitasModal = true"
                    >
                      Foto Identitas
                    </button>
                    <button
                      class="btn btn-secondary mx-1"
                      @click="showFotoMemegangIdentitasModal = true"
                    >
                      Foto Memegang Identitas
                    </button>
                    <button
                      class="btn btn-secondary mx-1"
                      @click="showFotoDiriModal = true"
                    >
                      Foto Diri
                    </button>
                    <button
                      class="btn btn-secondary mx-1"
                      @click="showBuktiPembayaranModal = true"
                    >
                      Foto Bukti Pembayaran
                    </button>
                    <button
                      class="btn btn-secondary mx-1"
                      @click="generateQrCode"
                      :disabled="alreadyGenerated"
                    >
                      Generate QR Code
                    </button>
                  </div>
                </div>
                <div class="row px-3" v-else>
                  <hr />
                  <div class="col-md-6 mb-3">
                    <label for="foto_diri" class="form-label"
                      >Foto Identitas</label
                    >
                    <input
                      class="form-control"
                      type="file"
                      @change="changeFotoIdentitas"
                      accept="image/png, image/jpeg"
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="foto_diri" class="form-label"
                      >Foto Memegang Identitas</label
                    >
                    <input
                      class="form-control"
                      type="file"
                      @change="changeFotoMemegangIdentitas"
                      accept="image/png, image/jpeg"
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="foto_diri" class="form-label">Foto Diri</label>
                    <input
                      class="form-control"
                      type="file"
                      @change="changeFotoDiri"
                      accept="image/png, image/jpeg"
                    />
                  </div>
                  <div class="col-md-6 mb-3">
                    <label for="foto_diri" class="form-label"
                      >Bukti Pembayaran</label
                    >
                    <input
                      class="form-control"
                      type="file"
                      @change="changeBuktiPembayaran"
                      accept="image/png, image/jpeg"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-3">
          <button
            class="btn btn-outline-success"
            @click="submitForm"
            :disabled="isLoading"
          >
            {{ isLoading ? "Loading..." : "Simpan" }}
          </button>
          <div
            class="alert alert-danger mt-3"
            role="alert"
            v-if="errorValidation"
          >
            Beberapa kolom belum diisi, harap periksa setiap tab untuk mengisi
            kolom yang wajib diisi.
          </div>
        </div>
      </div>
    </div>

    <!-- Modal foto bukti pembayaran -->
    <edit-foto-modal
      title="Edit foto bukti pembayaran"
      :showModal="showBuktiPembayaranModal"
      @closeModal="closeBuktiPembayaranModal"
      :isUploaded="anggota.bukti_pembayaran !== null"
      :isUploading="isUploadingFoto"
      @upload="uploadBuktiPembayaran"
    />

    <!-- Modal foto identitas -->
    <edit-foto-modal
      title="Edit foto identitas"
      :showModal="showFotoIdentitasModal"
      @closeModal="closeFotoIdentitasModal"
      :isUploaded="anggota.foto_identitas !== null"
      :isUploading="isUploadingFoto"
      @upload="uploadFotoIdentitas"
    />

    <!-- Modal foto memegang indentitas -->
    <edit-foto-modal
      title="Edit foto memegang identitas"
      :showModal="showFotoMemegangIdentitasModal"
      @closeModal="closeFotoMemegangIdentitasModal"
      :isUploaded="anggota.foto_memegang_identitas !== null"
      :isUploading="isUploadingFoto"
      @upload="uploadFotoMemegangIdentitas"
    />

    <!-- Modal foto diri -->
    <edit-foto-modal
      title="Edit foto diri"
      :showModal="showFotoDiriModal"
      @closeModal="closeFotoDiriModal"
      :isUploaded="anggota.foto_diri !== null"
      :isUploading="isUploadingFoto"
      @upload="uploadFotoDiri"
    />
  </div>
</template>

<script>
import {
  anggotaService,
  provinsiService,
  kotaService,
  kecamatanService,
  kelurahanService,
  kategoriHobiService,
  kategoriPekerjaanService,
  pekerjaanService,
  hobiService,
  countryService,
  qrCodeService,
} from "@/services";
import { required, email } from "vuelidate/lib/validators";
import EditFotoModal from "../EditFotoModal.vue";
export default {
  components: {
    EditFotoModal,
  },
  props: {
    initialAnggota: {
      type: Object,
    },
    isEdit: {
      type: Boolean,
    },
  },
  data() {
    return {
      anggota: this.initialAnggota,

      // Data untuk select
      kategori_hobi: [],
      hobi: [],
      kategori_pekerjaan: [],
      pekerjaan: [],
      provinsi: [],
      kota: [],
      kecamatan: [],
      kelurahan: [],
      provinsiDomisili: [],
      kotaDomisili: [],
      kecamatanDomisili: [],
      kelurahanDomisili: [],
      countries: [],
      jenis_identitas: [
        "KTP",
        "SIM",
        "Kartu Pelajar",
        "Kartu Keluarga",
        "Paspor",
      ],
      jenis_atribut: [
        "Plus Kemeja (Rp.200.000)",
        "Plus Kaos (Rp.150.000)",
        "Hanya KTA (Rp.55.000)",
      ],
      ukuran_atribut: [
        "XS (46 X 66 CM)",
        "S (48 X 68 CM)",
        "M (50 X 70 CM)",
        "L (52 X 72 CM)",
        "XL (54 X 74 CM)",
        "XXL (56 X 76 CM)",
        "3XL (58 X 76 CM)",
      ],
      agama: ["Islam", "Kristen", "Hindu", "Buddha", "Khonghucu"],
      jenis_kelamin: ["Laki-Laki", "Perempuan"],
      status: ["Belum Menikah", "Sudah Menikah", "Janda", "Duda"],
      status_anggota: [
        { label: "Menunggu Persetujuan", value: "WAITING APPROVAL" },
        { label: "Menunggu Pembayaran", value: "WAITING PAYMENT" },
        { label: "Menunggu Pencetakan", value: "WAITING PRODUCTION" },
        { label: "Disetujui", value: "APPROVED" },
        { label: "Ditolak", value: "REJECTED" },
      ],
      hubungan_keluarga: [
        "Kakek",
        "Nenek",
        "Ibu",
        "Ayah",
        "Paman",
        "Bibi",
        "Saudara/i",
        "Anak",
        "Mertua",
        "Mantu",
        "Ipar",
      ],
      locale: "id-ID",

      // Form State
      isLoading: false,
      isError: false,
      errorValidation: false,
      alreadyGenerated: false,

      // Modal specific state
      showFotoIdentitasModal: false,
      showFotoMemegangIdentitasModal: false,
      showFotoDiriModal: false,
      showBuktiPembayaranModal: false,
      isUploadingFoto: false,
    };
  },
  methods: {
    onProvinceChange(propinsi_id) {
      kotaService.getAll("", "", "", "", "", propinsi_id).then((resp) => {
        this.kota = resp.rows;
      });
    },
    onKotaChange(kota_id) {
      kecamatanService.getAll("", "", "", "", "", kota_id).then((resp) => {
        this.kecamatan = resp.rows;
      });
    },
    onKecamatanChange(kecamatan_id) {
      kelurahanService.getAll("", "", "", "", "", kecamatan_id).then((resp) => {
        this.kelurahan = resp.rows;
      });
    },
    onDomisiliProvinceChange(propinsi_id) {
      kotaService.getAll("", "", "", "", "", propinsi_id).then((resp) => {
        this.kotaDomisili = resp.rows;
      });
    },
    onDomisiliKotaChange(kota_id) {
      kecamatanService.getAll("", "", "", "", "", kota_id).then((resp) => {
        this.kecamatanDomisili = resp.rows;
      });
    },
    onDomisiliKecamatanChange(kecamatan_id) {
      kelurahanService.getAll("", "", "", "", "", kecamatan_id).then((resp) => {
        this.kelurahanDomisili = resp.rows;
      });
    },
    onKategoriPekerjaanChange(kategori_pekerjaan_id) {
      pekerjaanService
        .getAll("", "", "", "", "", kategori_pekerjaan_id)
        .then((resp) => {
          this.pekerjaan = resp.rows;
        });
    },
    onKategoriHobiChange(kategori_hobi_id) {
      hobiService.getAll("", "", "", "", "", kategori_hobi_id).then((resp) => {
        this.hobi = resp.rows;
      });
    },
    onJenisAtributChange() {
      this.anggota.ukuran_atribut = "";
    },

    changeFotoIdentitas(e) {
      const selectedFile = e.target.files[0];
      this.anggota.foto_identitas = selectedFile;
    },
    changeFotoMemegangIdentitas(e) {
      const selectedFile = e.target.files[0];
      this.anggota.foto_memegang_identitas = selectedFile;
    },
    changeFotoDiri(e) {
      const selectedFile = e.target.files[0];
      this.anggota.foto_diri = selectedFile;
    },
    changeBuktiPembayaran(e) {
      const selectedFile = e.target.files[0];
      this.anggota.bukti_pembayaran = selectedFile;
    },

    // Specific modal
    closeBuktiPembayaranModal() {
      this.showBuktiPembayaranModal = false;
    },
    closeFotoIdentitasModal() {
      this.showFotoIdentitasModal = false;
    },
    closeFotoMemegangIdentitasModal() {
      this.showFotoMemegangIdentitasModal = false;
    },
    closeFotoDiriModal() {
      this.showFotoDiriModal = false;
    },
    clearFileInput() {
      const x = document.querySelectorAll(".input_upload_foto");
      x.forEach((item, index, arr) => {
        arr[index].value = "";
      });
    },
    async uploadBuktiPembayaran(bukti_pembayaran) {
      if (bukti_pembayaran) {
        try {
          this.isUploadingFoto = true;
          const resp = await anggotaService.uploadBuktiPembayaran(
            this.anggota.id,
            bukti_pembayaran
          );
          this.$toast.success(resp.data.message);
          this.showBuktiPembayaranModal = false;
          this.clearFileInput();
          this.isUploadingFoto = false;
        } catch (error) {
          this.$toast.error(
            `Gagal mengupload bukti pembayaran: ${
              (error.response.data && error.response.data.message) ||
              error.message
            }`
          );
          this.isUploadingFoto = false;
        }
      }
    },
    async uploadFotoIdentitas(foto_identitas) {
      if (foto_identitas) {
        try {
          this.isUploadingFoto = true;
          const resp = await anggotaService.uploadFotoIdentitas(
            this.anggota.id,
            foto_identitas
          );
          this.$toast.success(resp.data.message);
          this.showFotoIdentitasModal = false;
          this.clearFileInput();
          this.isUploadingFoto = false;
        } catch (error) {
          this.$toast.error(
            `Gagal mengupload foto identitas: ${
              (error.response.data && error.response.data.message) ||
              error.message
            }`
          );
          this.isUploadingFoto = false;
        }
      }
    },
    async uploadFotoMemegangIdentitas(foto_memegang_identitas) {
      if (foto_memegang_identitas) {
        try {
          this.isUploadingFoto = true;
          const resp = await anggotaService.uploadFotoMemegangIdentitas(
            this.anggota.id,
            foto_memegang_identitas
          );
          this.$toast.success(resp.data.message);
          this.showFotoMemegangIdentitasModal = false;
          this.clearFileInput();
          this.isUploadingFoto = false;
        } catch (error) {
          this.$toast.error(
            `Gagal mengupload foto memegang identitas: ${
              (error.response.data && error.response.data.message) ||
              error.message
            }`
          );
          this.isUploadingFoto = false;
        }
      }
    },
    async uploadFotoDiri(foto_diri) {
      if (foto_diri) {
        try {
          this.isUploadingFoto = true;
          const resp = await anggotaService.uploadFotoDiri(
            this.anggota.id,
            foto_diri
          );
          this.$toast.success(resp.data.message);
          this.showFotoDiriModal = false;
          this.clearFileInput();
          this.isUploadingFoto = false;
        } catch (error) {
          this.$toast.error(
            `Gagal mengupload foto diri: ${
              (error.response.data && error.response.data.message) ||
              error.message
            }`
          );
          this.isUploadingFoto = false;
        }
      }
    },

    // Submit form
    async submitForm() {
      this.isError = false;
      this.errorValidation = false;
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isLoading = true;

        if (this.isEdit) {
          try {
            await anggotaService.update(this.$route.params.id, this.anggota);
            this.$toast.success("Data berhasil di update");
            this.$router.push({ name: "admin-anggota" });
          } catch (error) {
            this.isError = true;
            this.$toast.error(
              (error.response && error.response.data.message) || error.message
            );
          }
        } else {
          try {
            await anggotaService.create(this.anggota);
            this.$toast.success("Data berhasil di tambah");
            this.$router.push({ name: "admin-anggota" });
          } catch (error) {
            this.isError = true;
            this.$toast.error(
              (error.response && error.response.data.message) || error.message
            );
          }
        }

        this.isLoading = false;
      } else {
        this.errorValidation = true;
      }
    },

    generateRandomString(len) {
      const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      return [...Array(len)]
        .map(() => chars.charAt(Math.floor(Math.random() * chars.length)))
        .join("");
    },

    async generateQrCode() {
      try {
        const resp = await qrCodeService.generateForAnggota({
          anggota_id: this.anggota.id,
        });
        this.anggota.qr_code = resp.data.code;
      } catch (error) {
        error.response
          ? this.$toast.error(error.response.data.message)
          : this.$toast.error(error.message);
      }
      this.alreadyGenerated = true;
    },
  },
  computed: {
    hanyaKta() {
      return this.anggota.jenis_atribut === "Hanya KTA (Rp.55.000)";
    },
  },
  validations: {
    anggota: {
      nama_lengkap: {
        required,
      },
      no_hp: {
        required,
      },
      email: {
        required,
        email,
      },
      alamat: {
        required,
      },
      propinsi_id: {
        required,
      },
      kota_id: {
        required,
      },
      kecamatan_id: {
        required,
      },
      jenis_identitas: {
        required,
      },
      no_identitas: {
        required,
      },
      jenis_atribut: {
        required,
      },
      ukuran_atribut: {
        required: function () {
          return (
            this.hanyaKta ||
            (this.anggota.ukuran_atribut &&
              this.anggota.ukuran_atribut.length > 0) ||
            false
          );
        },
      },
    },
  },
  mounted() {
    // Get domisili KTP
    provinsiService.getAll(1000, "", 1).then((resp) => {
      this.provinsi = resp.rows;
      this.onProvinceChange(this.anggota.propinsi_id);
      this.onKotaChange(this.anggota.kota_id);
      this.onKecamatanChange(this.anggota.kecamatan_id);
    });

    // Get Domisili saat ini
    provinsiService.getAll(1000, "", 1).then((resp) => {
      this.provinsiDomisili = resp.rows;
      this.onDomisiliProvinceChange(this.anggota.current_domisili_propinsi_id);
      this.onDomisiliKotaChange(this.anggota.current_domisili_kota_id);
      this.onDomisiliKecamatanChange(
        this.anggota.current_domisili_kecamatan_id
      );
    });

    // Get hobi & pekerjaan
    kategoriHobiService.getAll(1000, "", 1).then((resp) => {
      this.kategori_hobi = resp.rows;
      this.onKategoriHobiChange(this.anggota.kategori_hobi_id);
    });

    kategoriPekerjaanService.getAll(1000, "", 1).then((resp) => {
      this.kategori_pekerjaan = resp.rows;
      this.onKategoriPekerjaanChange(this.anggota.kategori_pekerjaan_id);
    });

    // Get country
    countryService.getAll(1000, "", 1).then((resp) => {
      this.countries = resp.rows;
    });

    if (this.anggota.qr_code) {
      this.alreadyGenerated = true;
    }
  },
};
</script>

<style scoped></style>
