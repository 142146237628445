<template>
  <vue-final-modal
    v-model="showModal"
    classes="modal-container"
    content-class="modal-content"
    :click-to-close="false"
  >
    <span class="modal__title">{{ title }}</span>
    <div class="modal__content px-3 py-3">
      <div class="row">
        <div
          class="col-12 d-flex justify-content-between align-items-start"
          style="text-align: center"
        >
          <span class="text-secondary">Status saat ini:</span>
          <strong class="text-primary"
            >{{ isUploaded ? "Sudah" : "Belum" }} diupload</strong
          >
        </div>
        <div
          class="col d-flex justify-content-between align-items-start"
          style="text-align: center"
        >
          <input
            class="form-control input_upload_foto"
            type="file"
            @change="changeBuktiPembayaran"
            accept="image/png, image/jpeg"
          />
        </div>
      </div>
    </div>
    <div class="modal__action">
      <button
        class="btn btn-primary mx-2"
        @click="upload"
        :disabled="isUploading"
      >
        {{ isUploading ? "Loading.." : "Upload" }}
      </button>
      <button class="btn" @click="closeModal">Batal</button>
    </div>
  </vue-final-modal>
</template>

<script>
export default {
  name: "EditFotoModal",
  props: {
    title: {
      type: String,
    },
    showModal: {
      type: Boolean,
      default: false,
    },
    isUploaded: {
      type: Boolean,
    },
    isUploading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      bukti_pembayaran: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("closeModal");
    },
    changeBuktiPembayaran(e) {
      const selectedFile = e.target.files[0];
      this.bukti_pembayaran = selectedFile;
    },
    upload() {
      this.$emit("upload", this.bukti_pembayaran);
    },
  },
};
</script>

<style scoped>
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  margin: 0 1rem;
  padding: 1rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  background: #fff;
  max-width: 600px;
}
</style>
